import { none } from '@wix/site-search-common';
import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Header widget side by side',
  props: {
    autocomplete: {
      defaultValue: none<string>(),
    },
  },
  events: {
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
    searchTermChange: {},
  },
});
