import { UnitsMapType } from '../types/types';

export const STATIC_MEDIA_PREFIX_URL = 'https://static.wixstatic.com/media/';
export const WIX_PROTOCOL_MEDIA_PREFIX_URL = 'wix:image://v1/';
export const DEBOUNCE_DELAY_SUGGESTIONS = 100;
export const LOADER_TIMEOUT = 1000;
export const SEARCH_APP_APP_DEF_ID = '1484cb44-49cd-5b39-9681-75188ab429de';
export const MAIN_WIDGET_CONTROLLER_ID =
  '49a4ef91-9d6e-43ff-94de-88cb5481cdf5-qbz52';

export const BI_EVENTS_CONSTS = {
  clickOrigin: {
    autoComplete: 'autocomplete (left side)',
    suggestions: 'suggestions (right side)',
    stores: 'Stores widget',
  },
};

export const ALIGN_OPTIONS = {
  start: 'start',
  center: 'center',
  end: 'end',
} as const;

export const UNITS_MAP: UnitsMapType = {
  px: { componentUnit: 'PIXELS', variableUnit: 'px' },
  percentage: { componentUnit: 'PERCENTAGE', variableUnit: 'percentage' },
};

export const componentsIds = {
  stretchToggle: 'panelToggleSwitch1',
  alignmentThumbnail: 'panelThumbnails1',
  widthSlider: 'panelSlider1',
};

export const HEADER_MAX_PERCENTAGE = 100;
export const HEADER_MIN_PERCENTAGE = 20;

export const SLIDER_LIMITS = {
  percentage: { max: 100, min: 20 },
  px: { max: 300, min: 165 },
};

export const designVariablesNames = {
  searchBarWidth: 'searchBarWidth',
  searchBarAlign: 'searchBarAlign',
  seeAllButtonWidth: 'seeAllButtonWidth',
  seeAllButtonAlign: 'seeAllButtonAlign',
};

export const WIDGETS_NAMES = {
  mainWidget: {
    displayName: 'Search Suggestions Widget',
    nickName: 'Search Suggestions Widget',
  },
  searchHeader: {
    displayName: 'Search Header',
    nickName: 'Search Header',
  },
  searchHeaderSideBySide: {
    displayName: 'Search Header',
    nickName: 'Search Header Side By Side',
  },
  searchSuggestionsList: {
    displayName: 'Search Suggestions List',
    nickName: 'Search Suggestions List',
  },
  productGallery: {
    displayName: 'Product Gallery',
    nickName: 'Product Gallery',
  },
  searchBar: {
    displayName: 'Search Bar',
    nickName: 'Search Bar',
  },
  appsPagesRepeater: {
    displayName: 'Apps & Pages Repeater',
    nickName: 'Apps & Pages Repeater',
  },
  searchFooter: {
    displayName: 'Search Footer',
    nickName: 'Search Footer',
  },
};

export enum elementsDefaultText {
  showAllButton = 'Show All Results',
  closeButton = 'Close',
}

export enum ArticlesIds {
  mainWidget = 'f61eec8b-5e3f-4d35-99e1-ff43674f04a1',
}
