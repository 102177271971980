import { makeAutoObservable, observable } from 'mobx';

import { defaultAppSettings } from '@wix/search-settings-client';
import { none } from '@wix/site-search-common';

import { FederatedSuggestion } from '@/lib/domain/federatedSuggestions';
import { Resource, ResourceWithDefault } from '@/lib/store/resource';
import { FederatedAutocomplete } from '@/lib/domain/federatedAutocomplete';
import { SearchDocumentType } from '@wix/client-search-sdk';
import { DEBOUNCE_DELAY_SUGGESTIONS } from '../../../utils/constants';

const defaultSettings = {
  ...defaultAppSettings,
  limit: 4,
  debounceDelaySuggestions: DEBOUNCE_DELAY_SUGGESTIONS,
} as const;

export class Store {
  constructor(private _settings = new ResourceWithDefault(defaultSettings)) {
    makeAutoObservable<Store, '_suggestions' | '_autocomplete'>(this, {
      _suggestions: observable.ref,
      _autocomplete: observable.ref,
    });
  }

  public get settings() {
    return this._settings;
  }
  public set settings(value) {
    this._settings = value;
  }

  private _searchTerm = none<string>();
  public get searchTerm() {
    return this._searchTerm;
  }
  public set searchTerm(value) {
    this._searchTerm = value;
  }

  private _suggestions = new Resource<FederatedSuggestion[]>();
  public get suggestions() {
    return this._suggestions;
  }
  public set suggestions(value) {
    this._suggestions = value;
  }

  public get productDocuments() {
    return this.suggestions.data?.find(
      ({ documentType }) => documentType === SearchDocumentType.Products,
    );
  }

  private _autocomplete = new Resource<FederatedAutocomplete[]>();
  public get autocomplete() {
    return this._autocomplete;
  }
  public set autocomplete(value) {
    this._autocomplete = value;
  }

  public get topAutocomplete() {
    return this.autocomplete.data?.[0]?.values[0]?.query;
  }
}
