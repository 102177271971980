import { AppSettings, createSettingsClient } from '@wix/search-settings-client';
import { IWixAPI } from '@wix/yoshi-flow-editor';

import { reportError } from '../errors';
import { createSearchHttpClient } from './searchAppHttpClient';
import { ErrorMonitor } from '@wix/yoshi-flow-editor/build/cjs/exports/error-monitor';

export interface SearchAppSettingsClient {
  getAppSettings(): Promise<AppSettings>;
}

export const createSearchAppSettingsClient = ({
  isViewer,
  isSSR,
  wixCodeApi,
  errorMonitor,
}: {
  isViewer?: boolean;
  isSSR: boolean;
  wixCodeApi: IWixAPI;
  errorMonitor: ErrorMonitor;
}): SearchAppSettingsClient => {
  const settingsClient = createSettingsClient({
    httpClient: createSearchHttpClient({
      wixCodeApi,
      isSSR,
    }),
  });
  const getAppSettings = async () => {
    try {
      const appSettings = isViewer
        ? await settingsClient.getPublished()
        : await settingsClient.getSaved();

      return appSettings;
    } catch (error) {
      reportError(errorMonitor, error);
      return settingsClient.getDefault();
    }
  };
  return { getAppSettings };
};
