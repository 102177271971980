import { IResponseImage } from '@wix/client-search-sdk/dist/types/api/api.types';
import {
  STATIC_MEDIA_PREFIX_URL,
  WIX_PROTOCOL_MEDIA_PREFIX_URL,
} from './constants';

const IMAGE_WIDTH = 70;
const IMAGE_HEIGHT = 72;
const URI_SUFFIX = (width: number, height: number) =>
  `/#originWidth=${width}&originHeight=${height}`;

function convertUriToWixProtocol(
  uri: string,
  width: number,
  height: number,
): string {
  const withSizeSuffix = (baseUri: string) =>
    baseUri + URI_SUFFIX(width, height);

  if (uri.startsWith(WIX_PROTOCOL_MEDIA_PREFIX_URL)) {
    return withSizeSuffix(uri);
  }

  if (uri.startsWith(STATIC_MEDIA_PREFIX_URL)) {
    return withSizeSuffix(
      uri.replace(STATIC_MEDIA_PREFIX_URL, WIX_PROTOCOL_MEDIA_PREFIX_URL),
    );
  }

  return withSizeSuffix(WIX_PROTOCOL_MEDIA_PREFIX_URL + uri);
}

export function getSizedImageUrl(image: IResponseImage) {
  if (image) {
    return 'src' in image
      ? convertUriToWixProtocol(
          image.src,
          image.width || IMAGE_WIDTH,
          image.height || IMAGE_HEIGHT,
        )
      : 'width' in image
      ? convertUriToWixProtocol(image.name, image.width, image.height)
      : convertUriToWixProtocol(image.name, IMAGE_WIDTH, IMAGE_HEIGHT);
  }
}
