import { ISearchBoxWixCode } from '@wix/site-search-common';
import model from './model';
import { autorun, makeAutoObservable } from 'mobx';
import { trimStringSpaces } from '../../utils/utils';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  const {
    translations: { t },
  } = flowAPI;

  const view = {
    get searchBox(): ISearchBoxWixCode {
      return $w('#searchBox');
    },
  };

  const store = makeAutoObservable({
    searchTerm: '',
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});

      view.searchBox.onChange(() => {
        const value = view.searchBox.value;
        store.searchTerm = value;
      });

      autorun(() => {
        const { searchTerm } = store;

        $widget.fireEvent('searchTermChange', {
          value: trimStringSpaces(searchTerm),
        });
      });
    },
    exports: {},
  };
});
