import model from './model';
import {
  FederatedSuggestion,
  FederatedSuggestionDocument,
} from '../../lib/domain/federatedSuggestions';
import { RepeaterDataItem } from '../../lib/domain/repeaterDataItem';

export default model.createController(({ $bind, $widget, $props, $w }) => {
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      $widget.onPropsChanged((oldValue, newValue) => {
        // @ts-expect-error
        $w('#verticalsSuggestionRepeater').forEachItem(
          // @ts-expect-error
          ($item, itemData: FederatedSuggestion) => {
            $item('#verticalItemsWidget').searchTerm =
              newValue.searchTerm || '';
          },
        );
      }); // @ts-expect-error
      $w('#verticalsSuggestionRepeater').onItemReady(
        // @ts-expect-error
        ($item, itemData: FederatedSuggestion) => {
          $item('#title').text = itemData.title;
          $item('#verticalItemsWidget').suggestionsItems =
            itemData.documents.map<
              RepeaterDataItem<FederatedSuggestionDocument>
            >((item) => ({
              _id: item.id,
              itemData: { ...item },
            }));
        },
      );

      $bind('#verticalsSuggestionRepeater', {
        // @ts-expect-error
        data: () => {
          // @ts-expect-error
          $w('#verticalsSuggestionRepeater').data = []; // clearing the repeater in order to let it get updated in case of having items with the same id as the previous search
          return $props.verticalsSuggestionRepeaterData || [];
        },
      });
    },
    exports: {},
  };
});
