import { SEARCH_APP_DEF_ID } from '../../../../search-app/sled/drivers/constants';
import {
  QuerySuggestionsRepeaterItem,
  toRepeaterData,
} from './lib/querySuggestionsRepeaterItem';
import model from './model';
import { BI_EVENTS_CONSTS } from '../../utils/constants';
import { reportDocumentClickedBi } from '../../utils/biEventsLogger';

export default model.createController(
  ({ $w, $bindAll, $widget, flowAPI, $props }) => {
    const {
      controllerConfig: {
        wixCodeApi: { location, window, site },
      },
      translations: { t },
      bi,
    } = flowAPI;
    let searchTerm = '';
    const viewMode = window.viewMode;
    return {
      pageReady: async () => {
        $widget.fireEvent('widgetLoaded', {});
        $widget.onPropsChanged((oldProps, newProps) => {
          searchTerm = newProps.searchTerm || '';
        });
        // todo: when we have api for trending searches, update translation to be dynamic
        $w('#suggestionsTitle').text = t(
          'siteSearch.viewer.suggestionBox.searchSuggestions.title',
        );
        const siteStructure = await site.getSiteStructure();
        const searchPath = siteStructure.pages.find(
          // @ts-expect-error
          (page) => page.applicationId === SEARCH_APP_DEF_ID,
        )?.url;
        // @ts-expect-error
        $w('#repeater').onItemReady(
          // @ts-expect-error
          ($item, { itemData }: QuerySuggestionsRepeaterItem) => {
            $item('#suggestionItemText').text = itemData.query;
            $item('#suggestionItemText').tabIndex = 0;

            $item('#suggestionItemText').onClick(() => {
              reportDocumentClickedBi({
                resultClicked: itemData.query,
                pageUrl: `${location.baseUrl + searchPath}${
                  itemData.query ? '?q=' + itemData.query : ''
                }`,
                clickOrigin: BI_EVENTS_CONSTS.clickOrigin.autoComplete,
                target: searchTerm,
                viewMode,
                bi,
              });
              location.to?.(
                `${searchPath}${itemData.query ? '?q=' + itemData.query : ''}`,
              );
            });
          },
        );

        $bindAll({
          '#repeater': {
            // @ts-expect-error
            data: () => {
              const data = $props.values.reduce(
                toRepeaterData,
                [] as QuerySuggestionsRepeaterItem[],
              );
              // @ts-expect-error
              $w('#repeater').data = [];
              return data;
            },
          },
        });
      },
      exports: {},
    };
  },
);
