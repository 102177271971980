import model from '../../model';
import { openSearchAppSettingsPanel } from '../utils';
import { reportSuggestionElementsCheckboxBi } from '../../../../utils/biEventsLogger';

export const SEARCH_SUGGESTIONS = {
  label: 'Search suggestions',
  value: 'searchSuggestions',
  selected: true,
  disabled: false,
};
export const RESULT_SUGGESTIONS = {
  label: 'Result suggestions',
  value: 'resultSuggestions',
  selected: true,
  disabled: false,
};
export const SHOW_ALL_BUTTON = {
  label: 'Show All button',
  value: 'showAllButton',
  selected: true,
  disabled: false,
};

const translationsKeys = {
  [SEARCH_SUGGESTIONS.value]:
    'siteSearch.viewer.suggestionElements.chooseElements.elementSearchSuggestions',
  [RESULT_SUGGESTIONS.value]:
    'siteSearch.viewer.suggestionElements.chooseElements.elementResultSuggestions',
  [SHOW_ALL_BUTTON.value]:
    'siteSearch.viewer.suggestionElements.chooseElements.elementShowAllButton',
};

export const elementsToDisplayOptions = [
  {
    label: SEARCH_SUGGESTIONS.label,
    value: SEARCH_SUGGESTIONS.value,
    selected: SEARCH_SUGGESTIONS.selected,
    disabled: SHOW_ALL_BUTTON.disabled,
  },
  {
    label: RESULT_SUGGESTIONS.label,
    value: RESULT_SUGGESTIONS.value,
    selected: RESULT_SUGGESTIONS.selected,
    disabled: SHOW_ALL_BUTTON.disabled,
  },
  {
    label: SHOW_ALL_BUTTON.label,
    value: SHOW_ALL_BUTTON.value,
    selected: SHOW_ALL_BUTTON.selected,
    disabled: SHOW_ALL_BUTTON.disabled,
  },
];
type CheckboxGroupOptionType = (typeof elementsToDisplayOptions)[number];

export default model.createPanel(
  'Suggestion Elements',
  async ({ $w, flowAPI }) => {
    const {
      translations: { t },
      controllerConfig: { wixCodeApi },
      bi,
    } = flowAPI;
    const widgetApi = wixCodeApi.widget;
    const { editorSdk: editorSDK } =
      // @ts-expect-error
      await wixCodeApi?.mainFrameEditor?.getMainFrameEditorContext();

    const { getUserId, getMetaSiteId } = editorSDK?.info;

    const initPanel = async () => {
      const [widgetProps, uuid, msid] = await Promise.all([
        widgetApi?.getProps(),
        getUserId(),
        getMetaSiteId(),
      ]);
      const elementsCheckboxes = $w('#showElementsCheckboxGroup');
      const footerRichText = $w('#footerRichText');

      elementsCheckboxes.label = t(
        'siteSearch.viewer.suggestionElements.chooseToDisplayElements.text',
      );
      elementsCheckboxes.tooltip = t(
        'siteSearch.viewer.suggestionElements.chooseToDisplayElements.text',
      );
      footerRichText.text = t(
        'siteSearch.viewer.suggestionSettings.toChoosePagesShownAsSuggestions.text',
      );

      footerRichText.linkLabel = t(
        'siteSearch.viewer.suggestionSettings.toChoosePagesShownAsSuggestions.link',
      );

      elementsCheckboxes.options = elementsToDisplayOptions.map((element) => ({
        ...element,
        selected:
          widgetProps?.elementsToDisplay?.includes(element.value) ?? true,
        label: t(translationsKeys[element.value]),
      })) as CheckboxGroupOptionType[];

      const updateElementsToDisplay = () => {
        const values = elementsCheckboxes.value;

        if (values.includes(RESULT_SUGGESTIONS.value)) {
          widgetApi?.setProps({ elementsToDisplay: values });
          elementsCheckboxes.options = elementsCheckboxes.options.map(
            (option: CheckboxGroupOptionType) =>
              option.value === SHOW_ALL_BUTTON.value
                ? {
                    ...option,
                    disabled: false,
                  }
                : option,
          );
          return;
        }

        const valuesWithoutShowAllButton = values.filter(
          (value: string) => value !== SHOW_ALL_BUTTON.value,
        );

        elementsCheckboxes.options = elementsToDisplayOptions.map((option) =>
          option.value === SHOW_ALL_BUTTON.value
            ? { ...option, disabled: true }
            : option,
        );

        widgetApi?.setProps({ elementsToDisplay: valuesWithoutShowAllButton });
        elementsCheckboxes.value = valuesWithoutShowAllButton;
      };

      let previousCheckedValues = [...elementsCheckboxes.value];

      footerRichText.onClick(() => openSearchAppSettingsPanel(editorSDK));
      elementsCheckboxes.onChange(() => {
        if (previousCheckedValues.length < elementsCheckboxes.value.length) {
          const fountValue = elementsCheckboxes.value.find(
            (item: string) => !previousCheckedValues.includes(item),
          );
          const option = elementsCheckboxes.options.find(
            (option: CheckboxGroupOptionType) => option.value === fountValue,
          );
          reportSuggestionElementsCheckboxBi({
            bi,
            check_box_name: option.label,
            is_enabled: !option.disabled,
            ...(option.disabled ? {} : { check_box: option.selected }),
            uuid,
            msid,
          });
        } else {
          const fountValue = previousCheckedValues.find(
            (item: string) => !elementsCheckboxes.value.includes(item),
          );
          const option = elementsCheckboxes.options.find(
            (option: CheckboxGroupOptionType) => option.value === fountValue,
          );
          reportSuggestionElementsCheckboxBi({
            bi,
            check_box_name: option.label,
            is_enabled: !option.disabled,
            ...(option.disabled ? {} : { check_box: option.selected }),
            uuid,
            msid,
          });
        }
        updateElementsToDisplay();
        previousCheckedValues = [...elementsCheckboxes.value];
      });

      updateElementsToDisplay();
    };

    $w.onReady(initPanel);
  },
);
