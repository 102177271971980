import {
  FederatedAutocomplete,
  FederatedAutocompleteValue,
} from '@/lib/domain/federatedAutocomplete';
import { RepeaterDataItem } from '@/lib/domain/repeaterDataItem';

export type QuerySuggestionsRepeaterItem =
  RepeaterDataItem<FederatedAutocompleteValue>;

export const toRepeaterItem = (
  autocompleteItem: FederatedAutocompleteValue,
  index: number,
): QuerySuggestionsRepeaterItem => ({
  _id: String(index),
  itemData: autocompleteItem,
});

export const toRepeaterData = (
  data: QuerySuggestionsRepeaterItem[],
  result: FederatedAutocomplete,
): QuerySuggestionsRepeaterItem[] => [
  ...data,
  ...result.values.map(toRepeaterItem),
];
