import { ProductGalleryData } from '@/lib/domain/productGalleryData';
import { none } from '@wix/site-search-common';
import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { FederatedSuggestion } from '../../lib/domain/federatedSuggestions';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'Product Gallery slot',
  props: {
    // Here you can define your widget props
    products: {
      defaultValue: none<ProductGalleryData>(),
    },
    ready: {
      defaultValue: none<boolean>(),
    },
    title: {
      defaultValue: none<string>(),
    },
    searchTerm: {
      defaultValue: none<string>(),
    },
    suggestionsResult: {
      defaultValue: none<FederatedSuggestion[]>(),
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
