import type { IHttpClient } from '@wix/http-client';
import { ClientSearchSDK, SearchDocumentType } from '@wix/client-search-sdk';
import { Spec } from '@wix/site-search-common';
import { AppSettings } from '@wix/search-settings-client';
import { type ErrorMonitor } from '@wix/yoshi-flow-editor/error-monitor';
import { IWixAPI } from '@wix/yoshi-flow-editor';

import { Experiments } from '../model';
import { createSearchAppSettingsClient } from './searchAppSettingsClient';

export interface Services {
  readonly clientSearchSDK: ClientSearchSDK;
  readonly searchAppSettings: {
    getAppSettings(): Promise<AppSettings>;
  };
}

export const createServices = ({
  httpClient,
  errorMonitor,
  wixCodeApi,
  timeout = 25000,
  environment: { language, isViewer, isSSR },
  experiments,
}: {
  httpClient: IHttpClient;
  errorMonitor: ErrorMonitor;
  wixCodeApi: IWixAPI;
  timeout?: number;
  environment: {
    language: string;
    isViewer?: boolean;
    isSSR: boolean;
  };
  experiments: Experiments;
}): Services => ({
  clientSearchSDK: new ClientSearchSDK({
    httpClient,
    siteBaseUrl: wixCodeApi.location.baseUrl,
    language,
    timeout,
    excludeDocumentTypes: experiments.enabled(Spec.ProGallery)
      ? undefined
      : [SearchDocumentType.ProGallery],
    useRawProductDocuments: true,
  }),
  searchAppSettings: createSearchAppSettingsClient({
    isViewer,
    isSSR,
    errorMonitor,
    wixCodeApi,
  }),
});
