import { none } from '@wix/site-search-common';
import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
import { FederatedSuggestion } from '../../lib/domain/federatedSuggestions';

export default createBlocksModel({
  widgetName: 'Verticals repeater widget',
  props: {
    verticalsSuggestionRepeaterData: {
      defaultValue: none<FederatedSuggestion[]>(),
    },
    searchTerm: {
      defaultValue: none<string>(),
    },
  },
});
