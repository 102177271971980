import { I$W } from '@wix/yoshi-flow-editor';

type ComponentType<T> = T extends I$W<infer R> ? R[keyof R] : never;

export const toggleComponentVisibility = <T extends I$W>(
  component: ComponentType<T>,
  shouldShow: boolean,
) => {
  shouldShow ? component.expand() : component.collapse();
};
