import {
  documentClick,
  searchRequestFinished,
  suggestionSettingsToggleSrc99Evid406,
  uoUClickOnShowAllResultsSrc99Evid409,
  suggestionElementsCheckboxSrc99Evid407,
} from '@wix/bi-logger-wix-code-data/v2';
import type { documentClickParams } from '@wix/bi-logger-wix-code-data/v2/types';
import { FederatedSuggestion } from '../lib/domain/federatedSuggestions';
import { SearchDocumentType } from '@wix/client-search-sdk';
import { MAX_VERTICALS_SUGGESTIONS_TO_SHOW } from '../components/Main widget with store slot/viewer.controller';
import { FederatedAutocomplete } from '../lib/domain/federatedAutocomplete';
import { VisitorLogger } from '@wix/yoshi-flow-editor/external-types/bi';

export const reportDocumentClickedBi = ({
  viewMode,
  bi,
  ...biParams
}: {
  viewMode: string;
  bi: VisitorLogger | null | undefined;
} & documentClickParams) => {
  if (viewMode === 'Site') {
    bi?.report(documentClick({ ...biParams, isDemo: false }));
  }
};

export const reportDocumentShownBi = ({
  resultsArray,
  documentIds,
  origin,
  target,
  viewMode,
  bi,
}: {
  resultsArray: string;
  documentIds: string;
  origin: string;
  target: string;
  viewMode: string;
  bi: VisitorLogger | null | undefined;
}) => {
  if (viewMode === 'Site') {
    bi?.report(
      searchRequestFinished({
        origin,
        resultsArray,
        target,
        documentIds,
      }),
    );
  }
};

export const reportSuggestionSettingsToggleBi = ({
  value,
  bi,
  uuid,
  msid,
}: {
  value: boolean;
  bi: VisitorLogger | null | undefined;
  uuid: string;
  msid: string;
}) => {
  bi?.report(
    suggestionSettingsToggleSrc99Evid406({
      toggle: value,
      toggle_name: 'suggestions before search',
      // @ts-expect-error
      uuid,
      _msid: msid,
    }),
  );
};

export const reportUoUClickOnShowAllResultsBi = ({
  viewMode,
  bi,
}: {
  viewMode: string;
  bi: VisitorLogger | null | undefined;
}) => {
  if (viewMode === 'Site') {
    bi?.report(uoUClickOnShowAllResultsSrc99Evid409({}));
  }
};

export const reportSuggestionElementsCheckboxBi = ({
  check_box,
  check_box_name,
  is_enabled,
  bi,
  uuid,
  msid,
}: {
  check_box?: boolean;
  check_box_name: string;
  is_enabled?: boolean;
  bi: VisitorLogger | null | undefined;
  uuid: string;
  msid: string;
}) => {
  bi?.report(
    suggestionElementsCheckboxSrc99Evid407({
      check_box,
      check_box_name,
      is_enabled,
      // @ts-expect-error
      uuid,
      _msid: msid,
    }),
  );
};

interface DocumentTypeCount {
  [key: string]: number;
  all: number;
}

export const loggerHelper = {
  getDocumentResultsArrStringify: (data: FederatedSuggestion[]) =>
    JSON.stringify(
      data?.reduce<DocumentTypeCount>(
        (acc, { documentType, documents }) => {
          const countToAdd =
            documentType === SearchDocumentType.Products
              ? documents.length
              : documents.slice(0, MAX_VERTICALS_SUGGESTIONS_TO_SHOW).length;

          acc[documentType] = (acc[documentType] || 0) + countToAdd;
          acc.all += countToAdd;

          return acc;
        },
        {
          all: 0,
        },
      ),
    ),
  getDocumentIdsStringify: (data: FederatedSuggestion[]) =>
    JSON.stringify(
      data?.reduce<Record<string, string[]>>(
        (acc, { documentType, documents }) => {
          const relevantDocuments =
            documentType === SearchDocumentType.Products
              ? documents
              : documents.slice(0, MAX_VERTICALS_SUGGESTIONS_TO_SHOW);

          acc[documentType] = relevantDocuments.map((doc) => doc.id);
          return acc;
        },
        {},
      ),
    ),
  getResultsArrayAutoCompleteStringify: (data: FederatedAutocomplete[]) =>
    JSON.stringify(
      data.reduce<{
        all: number;
        [key: string]: number;
      }>(
        (acc, { documentType, values }) => {
          acc[documentType] = values.length;
          acc.all += values.length;
          return acc;
        },
        {
          all: 0,
        },
      ),
    ),
  getDocumentIdsAutoCompleteStringify: (data: FederatedAutocomplete[]) =>
    JSON.stringify(
      data.reduce<Record<string, string[]>>((acc, { documentType, values }) => {
        acc[documentType] = values.map((val) => val.query);
        return acc;
      }, {}),
    ),
};
