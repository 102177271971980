import { debounce } from 'lodash';
import { computed, makeObservable } from 'mobx';

import { getOrElse } from '@wix/site-search-common';
import { SearchDocumentType, SuggestionsResult } from '@wix/client-search-sdk';

import { Store } from './store';
import { Services } from '../services';

export class Controller {
  constructor(
    readonly store: Store,
    readonly services: Services,
    readonly useDemoContent = false,
  ) {
    makeObservable<Controller, 'handleSearchInputChange'>(this, {
      handleSearchInputChange: computed,
    });

    this.changeSearchTerm('');
    this.getAppSettings();
  }

  changeSearchTerm = (value: string) => {
    this.store.searchTerm = value;
    this.handleSearchInputChange();
  };

  private async getAppSettings() {
    const {
      services: { searchAppSettings },
    } = this;

    this.store.settings.updateStart();
    const settings = await searchAppSettings.getAppSettings();

    this.store.settings.updateSuccess({
      ...this.store.settings.data,
      ...settings,
    });
  }

  private get handleSearchInputChange() {
    const { debounceDelaySuggestions } = this.store.settings.data;
    return debounce(async () => {
      const { store } = this;

      const query = getOrElse(store.searchTerm)(() => '');

      await Promise.all([
        this.updateSuggestions(query),
        this.updateAutocomplete(query),
      ]);
    }, debounceDelaySuggestions);
  }

  private async updateSuggestions(query: string) {
    const {
      store,
      services: { clientSearchSDK },
    } = this;
    const { limit, isSeoHiddenIncluded: includeSeoHidden } =
      store.settings.data;

    store.suggestions.updateStart();

    const [trendingResult, federatedResult] = await Promise.allSettled([
      query ? null : this.fetchTrendingProducts(includeSeoHidden),
      clientSearchSDK.getFederatedSuggestions({
        query,
        limit,
        includeSeoHidden,
      }),
    ]);

    // disregard the federatedResults if the query changed while
    // the request was running
    if (store.searchTerm !== query) {
      return store.suggestions.data as SuggestionsResult[];
    }

    const isFulfilled = <T>(
      result: PromiseSettledResult<T>,
    ): result is PromiseFulfilledResult<T> => result.status === 'fulfilled';

    const trendingItems = isFulfilled(trendingResult)
      ? (trendingResult.value as SuggestionsResult[])
      : [];
    const federatedItems = isFulfilled(federatedResult)
      ? (federatedResult.value.results as SuggestionsResult[])
      : [];

    const categoryList = this.store.settings.data.categoryList;
    const filteredItems = federatedItems.filter(
      ({ documentType }) => categoryList[documentType].visible,
    );

    const suggestions = filteredItems.map((item) => {
      if (
        item.documentType === SearchDocumentType.Products &&
        trendingItems?.length > 0
      ) {
        return trendingItems[0];
      }
      return item;
    });

    store.suggestions.updateSuccess(suggestions);
  }

  private async fetchTrendingProducts(includeSeoHidden: boolean) {
    const {
      services: { clientSearchSDK },
    } = this;

    const { results } = await clientSearchSDK.getTrendingItems({
      documentTypes: [SearchDocumentType.Products],
      includeSeoHidden,
    });

    if (results && results.length > 0) {
      return results;
    }
    throw new Error('no trending products');
  }

  private async updateAutocomplete(query: string) {
    const {
      store,
      services: { clientSearchSDK },
    } = this;
    const { limit, isSeoHiddenIncluded: includeSeoHidden } =
      store.settings.data;

    if (!this.useDemoContent && query === '') {
      store.autocomplete.updateSuccess([]);
      return;
    }

    store.autocomplete.updateStart();

    const { results } = await clientSearchSDK.getFederatedAutocomplete({
      query,
      limit,
      includeSeoHidden,
    });

    // disregard the results if the query changed while
    // the request was running
    if (store.searchTerm !== query) {
      return;
    }

    const { categoryList } = store.settings.data;

    store.autocomplete.updateSuccess(
      results
        .filter(
          ({ documentType, values }) =>
            values.length > 0 && categoryList[documentType].visible,
        )
        .sort(
          (a, b) =>
            categoryList[a.documentType].index -
            categoryList[b.documentType].index,
        ),
    );
  }
}
