import { FederatedSuggestionDocument } from '@/lib/domain/federatedSuggestions';
import { none } from '@wix/site-search-common';
import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
import { RepeaterDataItem } from '../../lib/domain/repeaterDataItem';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'List item widget new',
  props: {
    // Here you can define your widget props
    suggestionsItems: {
      defaultValue: none<RepeaterDataItem<FederatedSuggestionDocument>[]>(),
    },
    searchTerm: {
      defaultValue: none<string>(),
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
