import { ISearchBoxWixCode } from '@wix/site-search-common';
import { SEARCH_APP_DEF_ID } from '../../../../search-app/sled/drivers/constants';
import model from './model';
import { debounce } from 'lodash';
import {
  DEBOUNCE_DELAY_SUGGESTIONS,
  elementsDefaultText,
} from '../../utils/constants';
import { getTranslationOrUserText, trimStringSpaces } from '../../utils/utils';

const SEARCH_FOCUS_DELAY = 1000;
export default model.createController(
  ({ $bindAll, $widget, flowAPI, $w, $props }) => {
    const { window, location, site } = flowAPI.controllerConfig.wixCodeApi;
    const {
      translations: { t },
      controllerConfig: { wixCodeApi },
    } = flowAPI;

    const view = {
      get searchBox(): ISearchBoxWixCode {
        return $w('#searchBox');
      },
    };

    return {
      pageReady: async () => {
        $w('#udpCloseButton').label = getTranslationOrUserText({
          label: $w('#udpCloseButton').label,
          translation: t('siteSearch.viewer.suggestionBox.closeButton'),
          defaultLabelInBlock: elementsDefaultText.closeButton,
        });

        const viewMode = wixCodeApi.window.viewMode;
        const siteStructure = await site.getSiteStructure();
        const searchPath = siteStructure.pages.find(
          // @ts-expect-error
          (page) => page.applicationId === SEARCH_APP_DEF_ID,
        )?.url;

        if (viewMode !== 'Editor') {
          // using timeout here because the lightbox executes this pageReady callback before the lightbox is actually opens
          setTimeout(() => {
            view.searchBox.focus();
          }, SEARCH_FOCUS_DELAY);
        }

        $bindAll({
          '#udpCloseButton': {
            onClick: () => {
              // @ts-expect-error-next-line
              window.lightbox.close();
            },
          },
        });

        view.searchBox.onSubmit((e) => {
          location.to?.(
            `${searchPath}${e.target.value ? '?q=' + e.target.value : ''}`,
          );
        });

        const debouncedSearchChange = debounce(() => {
          const searchTerm = trimStringSpaces(view.searchBox.value);
          $widget.fireEvent('searchTermChange', { value: searchTerm });
        }, DEBOUNCE_DELAY_SUGGESTIONS);

        view.searchBox.onChange(() => {
          debouncedSearchChange();
        });

        view.searchBox.onClear(() => {
          debouncedSearchChange();
        });

        view.searchBox.autocompleteEnabled = true;

        $widget.onPropsChanged((prev, next) => {
          view.searchBox.autocompleteValue = next.autocomplete ?? '';
        });
      },
      exports: {},
    };
  },
);
