function parseJSONDate(dateString: string): Date {
  if (typeof dateString !== 'string') {
    throw new TypeError('The input must be a string');
  }

  const parsedDate = new Date(dateString);

  if (isNaN(parsedDate.getTime())) {
    throw new Error('Invalid date string');
  }

  return parsedDate;
}

export const getDateFormatter = (locale: string) => {
  return (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    try {
      return new Intl.DateTimeFormat(locale, options).format(
        parseJSONDate(date),
      );
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };
};
