import model from './model';
import debounce from 'lodash/debounce';
import { BI_EVENTS_CONSTS } from '../../utils/constants';
import { reportDocumentClickedBi } from '../../utils/biEventsLogger';
import { itemReady } from './repeaterItemsReady';
import { VerticalsRepeaterItemClickHandlerArgs } from '../../types/types';

let searchTerm = '';
// using debounce here because repeater inside a repeater is a new feature that have unexpected behavior
const repeaterItemClickHandler = debounce(
  ({
    relativeUrl,
    flowAPI,
    viewMode,
    title,
    bi,
  }: VerticalsRepeaterItemClickHandlerArgs) => {
    const baseUrl = flowAPI.controllerConfig.wixCodeApi.location.baseUrl;

    reportDocumentClickedBi({
      resultClicked: title,
      pageUrl: baseUrl + relativeUrl,
      clickOrigin: BI_EVENTS_CONSTS.clickOrigin.suggestions,
      viewMode,
      target: searchTerm,
      bi,
    });
    flowAPI.controllerConfig.wixCodeApi.location.to!(relativeUrl);
  },
  10,
);
export default model.createController(
  ({ $w, $bind, $widget, flowAPI, $props }) => {
    const viewMode = flowAPI.controllerConfig.wixCodeApi.window.viewMode;
    return {
      pageReady: async () => {
        $widget.fireEvent('widgetLoaded', {});
        $widget.onPropsChanged((oldValue, newValue) => {
          if (newValue.searchTerm) {
            searchTerm = newValue.searchTerm || '';
          }
        });
        // @ts-expect-error
        $w('#verticalItemsRepeater').onItemReady(($item, itemData) => {
          itemReady(
            $item,
            itemData,
            flowAPI,
            viewMode,
            repeaterItemClickHandler,
          );
        });

        $bind('#verticalItemsRepeater', {
          // @ts-expect-error
          data: () => $props.suggestionsItems || [],
        });
      },
      exports: {},
    };
  },
);
