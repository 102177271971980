import { SEARCH_LIGHTBOX_TPA_PAGE_ID } from '@wix/site-search-common';
import model from './model';

// TODO: Clean up once Search Button is used
export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  const { window, site } = flowAPI.controllerConfig.wixCodeApi;
  return {
    pageReady: async () => {
      const siteStructure = await site.getSiteStructure({
        includePageId: true,
      });
      const lightboxId = siteStructure.lightboxes.find(
        ({ tpaPageId }) => tpaPageId === SEARCH_LIGHTBOX_TPA_PAGE_ID,
      )?.id;
      $widget.fireEvent('widgetLoaded', {});
      $w('#theButton').onClick(async () => {
        if (!lightboxId) {
          console.error('Lightbox not found');
          return;
        }
        await window.openLightboxById(lightboxId, {});
      });
    },
    exports: {},
  };
});
