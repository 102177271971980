import { none } from '@wix/site-search-common';
import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { elementsToDisplayOptions } from './blocksPanels/Suggestion Elements/panel.controller';

export type ElementsToDisplayValues =
  (typeof elementsToDisplayOptions)[number]['value'];

const model = createBlocksModel({
  widgetName: 'Main widget with store slot',
  props: {
    // Here you can define your widget props
    resultsBeforeInput: {
      defaultValue: none<boolean>(),
    },
    elementsToDisplay: {
      defaultValue: none<ElementsToDisplayValues[]>(),
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});

type CreateController = (typeof model)['createController'];

type ControllerParams = Parameters<Parameters<CreateController>['0']>['0'];

export type Experiments = ControllerParams['flowAPI']['experiments'];

/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default model;
