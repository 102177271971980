import { getOrElse, none, Some } from '@wix/site-search-common';
import { makeAutoObservable, observable } from 'mobx';

export class Resource<T> {
  public get data() {
    return this._data;
  }

  private _ready = none<boolean>();
  public get ready() {
    return this._ready;
  }

  constructor(private _data = none<T>()) {
    makeAutoObservable<Resource<T>, '_data'>(this, {
      _data: observable.ref,
    });
  }

  updateStart() {
    this._ready = false;
  }
  updateSuccess(value: Some<T>) {
    this._data = value;
    this._ready = true;
  }
}

export class ResourceWithDefault<T> {
  private readonly target: Resource<T>;
  constructor(
    private readonly defaultValue: Some<T>,
    initialValue = none<T>(),
  ) {
    makeAutoObservable(this);
    this.target = new Resource(initialValue);
  }

  get data(): Some<T> {
    const { defaultValue } = this;
    return getOrElse(this.target.data)(() => defaultValue);
  }
  get ready() {
    return this.target.ready;
  }

  updateStart() {
    this.target.updateStart();
  }

  updateSuccess(value: Some<T>) {
    this.target.updateSuccess(value);
  }
}
