import { EditorSDK } from '@wix/platform-editor-sdk';
import { SEARCH_APP_DEF_ID } from '../../../../../search-app/sled/drivers/constants';

export const SEARCH_APP_WIDGET_ID = '44c66af6-4d25-485a-ad9d-385f5460deef';

export const openSearchAppSettingsPanel = async (editorSDK: EditorSDK) => {
  editorSDK.editor.openSettingsPanel('', {
    appDefinitionId: SEARCH_APP_DEF_ID,
    widgetId: SEARCH_APP_WIDGET_ID,
  });
};
