import { SEARCH_APP_DEF_ID } from '../../../../search-app/sled/drivers/constants';
import { reportUoUClickOnShowAllResultsBi } from '../../utils/biEventsLogger';
import { elementsDefaultText } from '../../utils/constants';
import { getTranslationOrUserText } from '../../utils/utils';
import model from './model';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  const {
    controllerConfig: { wixCodeApi },
    translations: { t },
    bi,
  } = flowAPI;
  const viewMode = wixCodeApi.window.viewMode;
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});

      $w('#udpSeeAllResults').label = getTranslationOrUserText({
        label: $w('#udpSeeAllResults').label,
        translation: t('siteSearch.viewer.suggestionBox.showAllButton'),
        defaultLabelInBlock: elementsDefaultText.showAllButton,
      });

      let searchTerm = '';
      $widget.onPropsChanged((oldProps, newProps) => {
        if (newProps.searchTerm) {
          searchTerm = newProps.searchTerm;
        }
      });

      const siteStructure = await wixCodeApi.site.getSiteStructure();
      const searchPath = siteStructure.pages.find(
        // @ts-expect-error
        (page) => page.applicationId === SEARCH_APP_DEF_ID,
      )?.url;

      $w('#udpSeeAllResults').onClick(() => {
        reportUoUClickOnShowAllResultsBi({ viewMode, bi });
        wixCodeApi.location.to?.(
          `${searchPath}${searchTerm ? '?q=' + searchTerm : ''}`,
        );
      });
    },
    exports: {},
  };
});
