import { reaction, toJS } from 'mobx';
import model from './model';
import {
  loggerHelper,
  reportDocumentClickedBi,
} from '../../utils/biEventsLogger';
import { BI_EVENTS_CONSTS } from '../../utils/constants';
import { ProductItemClickFn } from '../../types/productTypes';
import { FederatedSuggestion } from '../../lib/domain/federatedSuggestions';

export default model.createController(
  ({ $bind, $props, $widget, $w, flowAPI }) => {
    const {
      controllerConfig: {
        wixCodeApi: {
          window: { viewMode },
          location: { baseUrl },
        },
      },
      bi,
    } = flowAPI;
    let searchTerm = '';
    let suggestionsResult: FederatedSuggestion[] = [];

    const getProducts = () =>
      toJS($props.products || { totalCount: 0, list: [] });

    const onProductClicked: ProductItemClickFn = (
      productId,
      product,
      index,
    ) => {
      const resultsArray =
        loggerHelper.getDocumentResultsArrStringify(suggestionsResult);
      reportDocumentClickedBi({
        bi,
        viewMode,
        documentId: productId,
        resultClicked: product.name,
        pageUrl: `${baseUrl}/product-page/${product.urlPart}`,
        clickOrigin: BI_EVENTS_CONSTS.clickOrigin.stores,
        target: searchTerm,
        searchIndex: index,
        documentType: product.documentType,
        resultsArray,
      });
    };

    return {
      pageReady: async () => {
        $widget.fireEvent('widgetLoaded', {});
        $widget.onPropsChanged((oldProps, newProps) => {
          searchTerm = newProps.searchTerm || '';
          suggestionsResult = newProps.suggestionsResult || [];
        });

        $w('#productsGallery').slot.setExternalDataSourceConfig({
          getProducts,
          onProductClicked,
        });

        reaction(
          () => $props.products,
          () => {
            if (getProducts().totalCount) {
              $w('#productsGallery').slot.initializeGallery();
            }
          },
          { fireImmediately: true },
        );
        $bind('#title', {
          text: () => $props.title || 'Products',
        });
      },
      exports: {},
    };
  },
);
