export const trimStringSpaces = (str: string) => {
  return str.trimStart().replace(/\s+/g, ' ');
};

export const getTranslationOrUserText = ({
  label,
  translation,
  defaultLabelInBlock,
}: {
  label: string;
  translation: string;
  defaultLabelInBlock: string;
}) => {
  if (!label || defaultLabelInBlock === label) {
    return translation;
  }

  return label;
};
