import { ISearchBoxWixCode } from '@wix/site-search-common';
import model from './model';
import { getTranslationOrUserText, trimStringSpaces } from '../../utils/utils';
import { elementsDefaultText } from '../../utils/constants';

export default model.createController(({ $bindAll, $widget, flowAPI, $w }) => {
  const { window } = flowAPI.controllerConfig.wixCodeApi;
  const {
    translations: { t },
  } = flowAPI;

  const view = {
    get searchBox(): ISearchBoxWixCode {
      return $w('#searchBox');
    },
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      // TODO: update button to new udp button
      $w('#closeButton').label = getTranslationOrUserText({
        label: $w('#closeButton').label,
        translation: t('siteSearch.viewer.suggestionBox.closeButton'),
        defaultLabelInBlock: elementsDefaultText.closeButton,
      });

      $bindAll({
        '#closeButton': {
          onClick: () => {
            // @ts-expect-error-next-line
            window.lightbox.close();
          },
        },
      });

      view.searchBox.onChange(() => {
        const searchTerm = trimStringSpaces(view.searchBox.value);
        $widget.fireEvent('searchTermChange', { value: searchTerm });
      });

      view.searchBox.autocompleteEnabled = true;

      $widget.onPropsChanged((prev, next) => {
        view.searchBox.autocompleteValue = next.autocomplete ?? '';
      });
    },
    exports: {},
  };
});
